import type { Provider } from "@angular/core";
import {
   providePmSuggestionsHttpPersistence,
   providePmSuggestionsMemoryPersistence,
} from "src/app/schedules/pm-suggestions/resource/persistence/pm-suggestions-persistence";
import { PmSuggestionsResourceService } from "src/app/schedules/pm-suggestions/resource/pm-suggestions-resource.service";

export const providePmSuggestionsResourceFromHttp = (): Array<Provider> => {
   return [providePmSuggestionsHttpPersistence(), PmSuggestionsResourceService];
};

export const providePmSuggestionsResourceFromMemory = (): Array<Provider> => {
   return [providePmSuggestionsMemoryPersistence(), PmSuggestionsResourceService];
};
